.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
}

.container.home {
  font-family: Roboto, sans-serif;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image {
  width: 200px;
}

.form-block {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form {
  width: 300px;
}

.jar-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  min-width: 100px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e8522d;
  color: #fff;
  cursor: pointer;
}

.jar-button.floating-button {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 10px;
  height: 40px;
  margin-right: 20px;
  margin-left: 20px;
  box-shadow: 1px 1px 11px 0 rgba(0, 0, 0, 0.67);
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
}

.jar-button.floating-button.disabled {
  position: fixed;
  z-index: 1;
  background-color: rgba(232, 82, 45, 0.6);
  box-shadow: none;
}

.header-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 2;
  height: 60px;
}

.content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-wrapper.dispatch-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.header-content {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: #e8522d;
  box-shadow: 0 1px 10px 0 rgba(51, 51, 51, 0.5);
  color: #fff;
}

.menu-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-button-wrapper.menu-button {
  cursor: pointer;
}

.text-block {
  padding: 5px 10px;
  font-family: Materialicons, sans-serif;
  font-size: 60px;
}

.menu-bg {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  background-color: hsla(0, 0%, 65.1%, 0.6);
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
}

.menu-bg.menu-bg-hidden {
  background-color: transparent;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
}

.menu-bg.menu-bg-hidden.hidden {
  display: none;
}

.hidden {
  display: none;
}

.menu-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f5f5f5;
  box-shadow: 1px 0 11px 0 #6d6d6d;
  -webkit-transition: left 500ms ease;
  transition: left 500ms ease;
}

.menu-wrapper.menu-wrapper-hidden {
  left: -75%;
  -webkit-transition: left 500ms ease;
  transition: left 500ms ease;
}

.menu-user-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Materialicons, sans-serif;
  color: #ee9a85;
  font-size: 150px;
}

.text-block-3.user-label {
  font-size: 30px;
  font-weight: 500;
}

.body {
  font-family: Roboto, sans-serif;
}

.store-label {
  margin-top: 20px;
  font-size: 18px;
}

.menu-items-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.menu-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.disconnect-wrapper {
  position: static;
}

.menu-bottom-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 1px;
  border-color: #f5f5f5;
  border-bottom-style: solid;
  background-color: #d1d1d1;
  cursor: pointer;
}

.menu-bottom-button.last {
  border-bottom-width: 0px;
}

.text-block-4 {
  font-size: 16px;
  text-transform: uppercase;
}

.item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 70px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.item.collect-item {
  border-top: 1px solid #d1d1d1;
}

.item.collect-item.active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #e8522d;
  color: #fff;
}

.item.preparation-item {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
}

.item.preparation-item.active {
  background-color: #e8522d;
  color: #fff;
}

.item.active {
  background-color: #e8522d;
  color: #fff;
}

.item.first {
  border-top: 1px solid #d1d1d1;
}

.item-label {
  padding-left: 20px;
  font-size: 25px;
}

.text-block-7 {
  padding-left: 30px;
  font-family: Materialicons, sans-serif;
  font-size: 45px;
}

.text-block-8 {
  font-family: Materialicons, sans-serif;
  color: #ee9a85;
  font-size: 45px;
}

.close-menu {
  position: absolute;
  top: 0px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.menu-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 35px;
}

.title {
  font-weight: 300;
}

.form-messages {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.success-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(156, 204, 101, 0.5);
  color: #333;
}

.success-message.hidden {
  display: none;
}

.error-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(244, 67, 54, 0.5);
}

.error-message.hidden {
  display: none;
}

.link-block {
  color: #333;
  text-decoration: none;
}

.loader-wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 65.1%, 0.6);
}

.loader-wrapper.hidden {
  display: none;
  -webkit-transition-property: none;
  transition-property: none;
}

.modal-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: hsla(0, 0%, 65.1%, 0.6);
}

.modal-wrapper.hidden {
  display: none;
}

.loader-content {
  padding: 20px;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 10px 0 rgba(51, 51, 51, 0.5);
}

.html-embed {
  padding: 10px;
  box-shadow: 1px 1px 3px 0 rgba(51, 51, 51, 0.66);
}

.confirmation-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  margin-right: 30px;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 7px;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 10px 0 rgba(51, 51, 51, 0.5);
}

.confirmation-modal.hidden {
  display: none;
}

.modal-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
}

.modal-content {
  display: block;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 300;
}

.modal-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-right: 20px;
  padding-bottom: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 5px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e8522d;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.modal-button.ok-button {
  border-radius: 7px;
}

.modal-button.ok-button.ok-error {
  width: 200px;
}

.modal-button.ko-button {
  border: 2px solid #333;
  border-radius: 7px;
  background-color: transparent;
  color: #333;
}

.modal-button.ko-button.hidden {
  display: none;
}

.scanner-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  margin-right: 30px;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 11px 0 rgba(51, 51, 51, 0.5);
}

.scanner-modal.hidden {
  display: none;
}

.scanner-modal-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
}

.form-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-field {
  margin-bottom: 0px;
}

.text-field.codebar-input {
  font-size: 20px;
}

.text-field-2 {
  border-style: none;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.qty-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  margin-right: 30px;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f5f5f5;
}

.modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  margin-right: 30px;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 10px 0 rgba(51, 51, 51, 0.5);
}

.modal.qty-modal {
  border-radius: 7px;
}

.modal.qty-modal.hidden {
  display: none;
}

.modal.error-modal {
  border-radius: 7px;
}

.modal.error-modal.hidden {
  display: none;
}

.modal.report-modal {
  border-radius: 7px;
}

.modal.report-modal.hidden {
  display: none;
}

.modal.delay-modal.hidden {
  display: none;
}

.modal.scanner-error-modal.hidden {
  display: none;
}

.text {
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.qty-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.qty-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #e8522d;
  font-family: Materialicons, sans-serif;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
}

.qty {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 50px;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: silver;
  font-size: 30px;
  font-weight: 500;
}

.content {
  width: 100%;
}

.content-container {
  overflow: visible;
  width: 100%;
  padding-bottom: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.content-container.dispatch-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.collect-indication {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.indication-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 23px;
  font-weight: 300;
  text-align: center;
}

.manual-link {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.manual-link.cleanlink {
  padding: 12px;
  border-radius: 7px;
  background-color: #e8522d;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.product-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer;
}

.product.first {
  border-top: 1px solid #d1d1d1;
}

.product-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-2 {
  max-width: 150px;
}

.product-description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.product-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.product-brand {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.product-label {
  padding-right: 5px;
  padding-left: 5px;
}

.product-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.product-details-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-key {
  width: 100px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  font-weight: 500;
}

.product-details-value {
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.product-ean {
  padding: 5px 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #e8522d;
  font-size: 18px;
  font-weight: 500;
}

.product-list-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
}

.product-list-wrapper.hidden {
  display: none;
}

.product-list-shelf {
  padding-left: 30px;
}

.items-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.items-tab {
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background-color: hsla(0, 0%, 82%, 0.4);
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.items-tab.active {
  background-color: #e8522d;
  color: #fff;
}

.product-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-image.preparation-product {
  padding-top: 40px;
}

.image-3 {
  width: 100%;
  max-height: 500px;
  max-width: 500px;
  box-shadow: 1px 1px 10px 0 rgba(51, 51, 51, 0.5);
}

.product-details-description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-title {
  padding: 5px 10px 20px;
}

.product-name {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.product-attributes {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.product-attributes-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-attributes-key {
  width: 200px;
  text-transform: uppercase;
}

.product-details-ean {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #e8522d;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.report-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.report-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.report-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.report-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.report-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Materialicons, sans-serif;
  color: #ee9a85;
  font-size: 30px;
}

.report-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.report-content-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  padding: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
}

.report-content-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.report-qty {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
}

.report-clear {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #e8522d;
  font-family: Materialicons, sans-serif;
  color: #fff;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.report-subtitle {
  padding-right: 5px;
  padding-left: 5px;
}

.product-list-indication {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
}

.product-list-overlay-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.product-list-overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.product-list-overlay-indication {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 60px 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
}

.error-list {
  list-style-type: none;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.rest-qty {
  font-size: 20px;
  font-weight: 500;
}

.report-type {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.checkbox-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.checked {
  font-family: Materialicons, sans-serif;
  color: #707070;
  font-size: 30px;
}

.unchecked {
  font-family: Materialicons, sans-serif;
  font-size: 30px;
}

.check {
  font-family: Materialicons, sans-serif;
  color: #ee9a85;
  font-size: 30px;
}

.report-label {
  padding-left: 10px;
}

.close-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Materialicons, sans-serif;
  font-size: 50px;
  cursor: pointer;
}

.text-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 50px;
}

.collect-report {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.report-title-subtitle {
  padding: 5px;
  font-size: 18px;
}

.order-dispatch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dispatch-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}

.dispatch-indication {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 150px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.dispatch-codebar {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Idautomationhc39m code 39 barcode', sans-serif;
  color: #333;
  font-size: 30px;
}

.dispatch-codebar.dispatch-codebar-hider {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 20px;
  background-color: #fff;
}

.dispatch-codebar.dispatch-codebar-hider.hidden {
  display: none;
}

.text-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Materialicons, sans-serif;
  color: #ee9a85;
  font-size: 90px;
}

.close-dispatch {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Materialicons, sans-serif;
  font-size: 70px;
  cursor: pointer;
}

.scanner-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

.text-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ff3200;
}

.dispatch-product-overlay-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

.dispatch-product-overlay-wrapper.hidden {
  display: none;
}

.dispatch-product-overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.snack-wrapper {
  position: relative;
  top: 0px;
  width: 70%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  -webkit-transition: top 300ms cubic-bezier(.55, .085, .68, .53);
  transition: top 300ms cubic-bezier(.55, .085, .68, .53);
}

.snack-wrapper.snack-hidden {
  top: -60px;
  display: block;
  overflow: hidden;
  height: 60px;
  -webkit-transition: top 200ms ease;
  transition: top 200ms ease;
}

.snack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(51, 51, 51, 0.5);
}

.snack.success {
  background-color: #65ca8e;
}

.snack.error {
  background-color: #ee9a85;
}

.snack.info {
  background-color: #227092;
}

.snack-text {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.overlay-snack-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 70%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  -webkit-transition: top 500ms ease;
  transition: top 500ms ease;
}

.overlay-snack-wrapper.overlay-snack-wrapper-hidden {
  top: -80px;
  height: 60px;
  -webkit-transition: top 200ms cubic-bezier(.55, .085, .68, .53);
  transition: top 200ms cubic-bezier(.55, .085, .68, .53);
}

.overlay-snack {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(51, 51, 51, 0.5);
  text-align: center;
}

.overlay-snack.error {
  background-color: #ee9a85;
}

.overlay-snack.success {
  background-color: #65ca8e;
}

.overlay-snack.info {
  background-color: #227092;
}

.text-block-19 {
  font-size: 20px;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 500px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-7 {
  width: 400px;
  background-color: transparent;
  box-shadow: 0 1px 3px 0 #000;
}

.modal-message {
  font-size: 16px;
  text-align: center;
}

.div-block-8.commandlistitem {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 7px;
  background-color: hsla(0, 0%, 84.7%, 0.43);
}

.collectscontainer {
  padding: 10px;
}

.preparation-form-wrapper {
  padding-right: 20px;
  padding-left: 20px;
}

.prepscontainer {
  padding-right: 10px;
  padding-left: 10px;
}

.pagination-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination-number {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 26px;
}

.pagination-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background-color: #e8522d;
  font-family: 'Fontawesome webfont', sans-serif;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.pagination-arrow.right {
  width: 80px;
  border-radius: 0px 7px 7px 0px;
  text-decoration: none;
}

.pagination-arrow.disabled {
  background-color: rgba(232, 82, 45, 0.4);
  cursor: default;
}

.pagination-arrow.left {
  width: 80px;
  text-decoration: none;
}

.product-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.product-actions.hidden {
  display: none;
}

.product-remove {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Fontawesome, sans-serif;
  color: #333;
  font-size: 40px;
  text-decoration: none;
}

.product-remove.hidden {
  display: none;
}

.package-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.package-info.package-done {
  padding-top: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.package-info-title {
  font-size: 24px;
  font-weight: 700;
}

.package-info-indication {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  text-align: left;
}

.package-info-spec {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.package-info-spec-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
}

.package-info-spec-label {
  width: 300px;
  padding-right: 20px;
}

.package-info-spec-value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100px;
  padding-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
}

.package-weight-input {
  width: 60px;
  margin-bottom: 0px;
  text-align: center;
}

.form-block-4 {
  margin-bottom: 0px;
}

.form-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.package-weight-indication {
  margin-bottom: 20px;
  font-weight: 400;
}

.package-info-total-product {
  width: 100%;
  padding-left: 20px;
  text-align: left;
}

.package-total-weigth {
  width: 100%;
  padding-left: 20px;
  text-align: left;
}

.text-block-22 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.text-block-23 {
  padding-right: 10px;
  padding-left: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 28px;
}

.products-hint {
  padding: 20px 10px;
  text-align: center;
}

.scanner-download-link {
  color: #e8522d;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.user-hint {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.dispatch-indication-order {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
}

.bold {
  font-size: 30px;
  font-weight: 700;
}

.package-bulky {
  width: 100%;
  padding-left: 20px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.wc-hint {
  padding: 20px 10px;
  text-align: center;
}

.wc-hint.wc-title-hint {
  padding-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
}

.wc-container {
  padding-right: 10px;
  padding-left: 10px;
}

.dispatch-codebar-hider {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 20px;
  background-color: #fff;
}

.div-block-11 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.collect-cache {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.collect-cache-label {
  padding: 10px 20px 10px 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
}

.collect-cache-delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Fontawesome, sans-serif;
  color: #e8522d;
  font-size: 20px;
  cursor: pointer;
}

.collect-cache-table {
  overflow: auto;
  max-height: 300px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 65.1%, 0.6);
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.text-block-26 {
  font-family: 'Fontawesome webfont', sans-serif;
}

.collect-cache-empty {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.collect-cache-empty.hidden {
  display: none;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.collect-cache-status {
  padding: 2px 10px;
  border-radius: 6px;
  background-color: #20a757;
  font-family: 'Fontawesome webfont', sans-serif;
  color: #fff;
}

.collect-cache-status.pending {
  background-color: #991d00;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 991px) {
  .content-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .modal-actions {
    padding-left: 20px;
  }

  .content-container.dispatch-container {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .items-tab.active {
    background-color: #e8522d;
  }

  .div-block-4 {
    padding-left: 10px;
  }

  .dispatch-title {
    font-size: 22px;
  }

  .dispatch-indication {
    width: 100%;
    padding: 20px;
    font-size: 18px;
    text-align: center;
  }

  .div-block-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .text-block-14 {
    font-family: Merriweather, serif;
  }

  .colis {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-9 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .package-desc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .package-desc-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .package-desc-label {
    min-width: 200px;
    padding-right: 20px;
  }

  .product-actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .product-remove {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Fontawesome, sans-serif;
    color: #333;
    font-size: 40px;
    text-decoration: none;
  }

  .package-info-spec-value {
    min-width: 100px;
    text-align: center;
  }

  .package-weight-input {
    text-align: center;
  }

  .package-info-total-product {
    width: 100%;
    padding-left: 20px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .package-info-spec-label {
    width: 180px;
  }

  .package-info-total-product {
    padding-left: 20px;
    text-align: left;
  }
}

@media screen and (max-width: 479px) {
  .container {
    padding-top: 50px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-family: Montserrat, sans-serif;
  }

  .container.home {
    display: block;
  }

  .div-block {
    padding: 20px;
  }

  .jar-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 35px;
    min-width: 100px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #e8522d;
    color: #fff;
    cursor: pointer;
  }

  .jar-button.floating-button {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 10px;
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
    box-shadow: 1px 1px 11px 0 rgba(0, 0, 0, 0.67);
    text-transform: uppercase;
  }

  .jar-button.floating-button.disabled {
    background-color: rgba(232, 82, 45, 0.6);
    box-shadow: none;
  }

  .header-wrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
  }

  .content-wrapper {
    width: 100%;
    height: 100vh;
    padding-right: 20px;
    padding-left: 20px;
    background-color: transparent;
  }

  .header-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: #e8522d;
    color: #fff;
  }

  .menu-button-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .menu-button-wrapper.menu-button {
    cursor: pointer;
  }

  .text-block {
    font-size: 25px;
    cursor: pointer;
  }

  .menu-bg {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: hsla(0, 0%, 65.1%, 0.6);
    -webkit-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
  }

  .menu-bg.menu-bg-hidden {
    display: block;
    background-color: transparent;
    -webkit-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
  }

  .menu-bg.menu-bg-hidden.hidden {
    display: none;
  }

  .hidden {
    display: none;
  }

  .menu-wrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    box-shadow: 1px 1px 11px 0 #646161;
    -webkit-transition: left 500ms ease;
    transition: left 500ms ease;
  }

  .menu-wrapper.menu-wrapper-hidden {
    left: -80%;
    -webkit-transition: left 500ms ease;
    transition: left 500ms ease;
  }

  .menu-user-section {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .text-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 140px;
    height: 140px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Materialicons, sans-serif;
    color: rgba(232, 82, 45, 0.56);
    font-size: 140px;
  }

  .text-block-3 {
    font-size: 24px;
    font-weight: 700;
  }

  .text-block-3.user-label {
    font-size: 15px;
    text-transform: uppercase;
  }

  .body {
    font-family: Roboto, sans-serif;
  }

  .store-label {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .menu-items-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .menu-items {
    height: 100%;
  }

  .disconnect-wrapper {
    position: relative;
  }

  .menu-bottom-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #d1d1d1;
    cursor: pointer;
  }

  .text-block-4 {
    font-size: 14px;
    text-transform: none;
  }

  .text-block-5 {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-left: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Materialicons, sans-serif;
    font-size: 35px;
  }

  .div-block-2 {
    height: 70px;
  }

  .item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    cursor: pointer;
  }

  .item.collect-item {
    border-top: 1px solid #d1d1d1;
  }

  .item.collect-item.active {
    background-color: #e8522d;
    color: #fff;
  }

  .item.preparation-item {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
  }

  .item.preparation-item.active {
    background-color: #e8522d;
    color: #fff;
  }

  .item-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
  }

  .text-block-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Materialicons, sans-serif;
    font-size: 22px;
  }

  .text-block-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 45px;
    height: 45px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Materialicons, sans-serif;
    font-size: 22px;
  }

  .close-menu {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 60px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ee9a85;
    cursor: pointer;
  }

  .menu-title-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
  }

  .title {
    font-size: 17.5px;
    font-weight: 300;
  }

  .confirmation-modal {
    box-shadow: 1px 1px 11px 0 rgba(51, 51, 51, 0.5);
  }

  .modal-title {
    font-size: 14px;
  }

  .modal-button {
    cursor: pointer;
  }

  .modal-button.ok-button {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .modal-button.ok-button.disabled {
    background-color: rgba(232, 82, 45, 0.5);
  }

  .modal-button.ko-button {
    border: 2px solid #333;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: transparent;
    color: #333;
  }

  .scanner-modal {
    border-radius: 7px;
  }

  .scanner-modal-title {
    font-size: 18px;
    font-weight: 500;
  }

  .form-block-2 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .text-field-2 {
    margin-bottom: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 65.1%, 0.6);
    border-radius: 4px;
  }

  .content-container {
    padding-bottom: 50px;
    background-color: #fff;
  }

  .collect-indication {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .indication-text {
    padding: 14px 10px 4px;
    border-radius: 4px;
    background-color: transparent;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
  }

  .manual-input-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .manual-link.cleanlink {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    background-color: #e8522d;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
  }

  .product {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-bottom: 1px solid #d1d1d1;
    background-color: transparent;
  }

  .product-img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-2 {
    max-width: 150px;
    margin-bottom: 8px;
  }

  .product-description {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 5px;
    padding-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product-brand {
    padding-right: 5px;
    padding-left: 5px;
  }

  .product-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product-details-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product-ean {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .product-list-shelf {
    overflow: hidden;
    padding-left: 0px;
    text-align: center;
    text-shadow: 1px 1px 0 rgba(175, 169, 169, 0.25);
  }

  .products {
    padding-bottom: 60px;
  }

  .items-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid hsla(0, 0%, 65.1%, 0.6);
  }

  .items-tab {
    width: 45%;
    padding: 10px;
    border-radius: 10px;
    background-color: hsla(0, 0%, 65.1%, 0.6);
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }

  .items-tab.active {
    background-color: #e8522d;
    color: #fff;
  }

  .items-tab.active {
    width: 45%;
    font-size: 12px;
  }

  .products-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: left;
  }

  .product-image {
    width: 100%;
  }

  .image-3 {
    box-shadow: none;
  }

  .report-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }

  .report-list {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .report-item {
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .report-item.found-report {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .report-content-title {
    width: auto;
  }

  .report-clear {
    position: absolute;
    top: 10px;
    right: 0px;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    font-size: 15px;
  }

  .div-block-3 {
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    width: auto;
    text-align: right;
  }

  .div-block-4 {
    padding: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .rest-qty {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 5px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .dispatch-title {
    margin-top: 0px;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    text-align: center;
  }

  .dispatch-indication {
    font-size: 14px;
  }

  .div-block-5 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .dispatch-codebar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 120px;
    margin-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-block-16 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    padding-top: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Materialicons, sans-serif;
    color: #ee9a85;
    font-size: 90px;
  }

  .close-dispatch {
    width: 40px;
    height: 40px;
    font-size: 35px;
  }

  .dispatch-product-overlay-wrapper {
    padding: 10px;
  }

  .overlay-wrapper {
    display: block;
  }

  .overlay-wrapper.hidden {
    display: none;
  }

  .snack.error {
    padding-right: 10px;
    padding-left: 10px;
  }

  .snack-text {
    text-align: center;
  }

  .overlay-snack.error {
    background-color: #ee9a85;
  }

  .form-block-3 {
    margin-bottom: 5px;
  }

  .div-block-8 {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 7px;
    background-color: hsla(0, 0%, 84.7%, 0.43);
  }

  .text-block-21 {
    font-weight: 700;
  }

  .colis {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .package-desc {
    width: 100%;
  }

  .package-desc-label {
    min-width: 160px;
  }

  .package-desc-value {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .package-info-indication {
    font-size: 12px;
  }

  .package-info-spec-label {
    font-size: 14px;
  }

  .package-info-spec-value {
    font-size: 14px;
  }

  .package-weight-indication {
    width: 100%;
    margin-bottom: 10px;
  }

  .text-block-23 {
    font-size: 18px;
  }

  .products-hint {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .products-hint.hidden {
    display: none;
  }

  .user-hint {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 300;
  }

  .dispatch-indication-order {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 28px;
  }

  .bold {
    font-size: 36px;
  }

  .collect-cache {
    cursor: pointer;
  }

  .collect-cache-delete {
    padding-right: 11px;
  }

  .text-block-27 {
    padding-bottom: 5px;
  }

  .collect-cache-empty {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .collect-cache-empty.hidden {
    display: none;
  }

  .collect-cache-status {
    font-family: 'Fontawesome webfont', sans-serif;
  }
}

@font-face {
  font-family: 'Fontawesome webfont';
  src: url('../fonts/fontawesome-webfont.woff2') format('woff2'), url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Materialicons';
  src: url('../fonts/MaterialIcons-Regular.woff2') format('woff2'), url('../fonts/MaterialIcons-Regular.eot') format('embedded-opentype'), url('../fonts/MaterialIcons-Regular.woff') format('woff'), url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Idautomationhc39m code 39 barcode';
  src: url('../fonts/IDAutomationHC39M%20Code%2039%20Barcode.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Fontawesome';
  src: url('../fonts/FontAwesome.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}